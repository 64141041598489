<template>
  <div class="Poster">
    <div class="top-titles">
      <div class="title"><span>轮播图</span>/轮播图详情修改</div>

    </div>
                 <div class="top-box-image">
                    <span>轮播图</span>
                    <el-upload
                      class="avatar-uploader"
                      :show-file-list="true"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                      :action="upLoadUrl"
                      :data="headerMsg">
                      <img v-if="listpar.slideshowImg" :src="ImgUrl+listpar.slideshowImg" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div>
                    <div class="Slideshowpar-box">
                        <el-radio v-model="radio" label="1">详情</el-radio>
                        <el-radio v-model="radio" label="0">宣传图</el-radio>
                        <el-radio v-model="radio" label="2">不跳转</el-radio>
                    </div>

                      <div class="Slideshowpar-text" v-if="radio == '1'">
                        <span>带入详情字段</span>
                          <el-select v-model="listpar.biz_recruitid" placeholder="请选择标题" auto-complete="true">
                            <el-option
                              v-for="(r, i) in seek"
                              :key="i"
                              :label="r.headline"
                              :value="r.id"
                            ></el-option
                          ></el-select>
                      </div>
                

                  <div class="top-box-image" v-if="radio == '0'">
                    <span>详情图</span>
                    <el-upload
                      class="avatar-uploader"
                      :show-file-list="true"
                      :on-success="handleAvatarSucce"
                      :before-upload="beforeAvatarUpload"
                      accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                      :action="upLoadUrl"
                      :data="headerMsg">
                      <img v-if="listpar.detailshowImg" :src="ImgUrl+listpar.detailshowImg" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                </div>

                <div class="top-box-box"> 
                    <el-button type="primary" class="biy-icon" @click="onSubmit()">提交</el-button>
                </div>

  </div>
</template>
<script>
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
     ImgUrl:config.ImgUrl,
      // 上传图片的地址
      upLoadUrl:config.uploadingimg,

      headerMsg:{
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        uploadfile:'file',
      },

      seek:{},
            // 上传图片
    
      listpar:{},
       list:[],

      // 选项
       radio: '1',
    };
  },
  created() {
      this.getSong();
      this.getSongs();
  },
  mounted() {},

  methods: {
    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetch',
        bizCatalog:'CyclePicShowEntity',
        bizId:this.$route.params.id,
        })
 
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;
   
             this.radio = res.Result.ofType.toString()
          
      },

    //详情字段多选
      async getSongs(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: 1,
        pageSize: 300,
        bizCatalog:'RecruitPlaterA',
        query:{
          statusCode:2,
        }, })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.seek = res.Result.pageData

      },



    // 修改
    async onSubmit(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'upsert',
        bizCatalog:'CyclePicShowEntity',
        bizData:{
         id:this.$route.params.id,
         slideshowImg:this.listpar.slideshowImg,
         ofType:this.radio,
         detailshowImg:this.listpar.detailshowImg,
         biz_recruitid:this.listpar.biz_recruitid,
        }
        })
      
        if(this.$common.verifyAjaxResult(res,false)==false) return;
       this.$alert('修改成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push({ path: `/home/slideshow`});
          }
        });
      },


          // 图片上传轮播图
    handleAvatarSuccess(res, file) {
       
  
      // 图片路径拼接
      this.listpar.slideshowImg = res.Result
    },

      // 图片上传详情页
    handleAvatarSucce(res, file) {
   
 
      // 图片路径拼接
      this.listpar.detailshowImg = res.Result
    },




    beforeAvatarUpload() {
      console.log("1");
    },


// 跳转
    bianji(e){
        this.$router.push({ path: `/home/rolelistpar/${e.id}` });
    },

  },
  computed: {},
};
</script>


<style  scoped>
.top-box-box{
    width: 200px;
    margin: 0 auto;
}
.top-box-box .biy-icon{
    width: 200px;
}
/* 上传 */
.top-box-image {
  width: 300px;
  display: flex;
  justify-content: space-around;
  height: 100px;
  font-size: 14px;
  overflow: hidden;
  margin: 0px auto;
}
.top-box-image span{
  line-height: 78px;
}
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon {
  border: 1px solid rgba(153, 153, 153, 0.637);
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  height: 78px;
  display: block;
}
.div-img{
    width: 100%;
    text-align: center;
    padding-top: 50px;
}
.div-img img{
    width: 300px;
}
.particulars-map{
  width: 100%;
  display: flex;
  justify-content: center;
}
/* 选择框 */
.Slideshowpar-box{
  width: 250px;
  margin: 0 auto;
  padding: 50px 0
}
.Slideshowpar-text{
  width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0px 0 50px 0;
}
.Slideshowpar-text span{
  display: block;
  width: 120px;
  line-height: 40px;
  margin: 0 auto; 
}
@import "../../assets/css/parstyle.css";
</style>